<template>
	<v-container fluid tag="section">
		<ti-card :icon="$icons.tracks">
			<template #title>Tracks</template>
			<template #title-actions-shown>
				<v-btn color="success" elevation="2" fab small :to="{name: 'TracksNew'}">
					<v-icon>{{ $icons.new }}</v-icon>
				</v-btn>
			</template>
			<v-data-table :headers="headers" :items="tracks" item-key="id" :search="search">
				<template v-slot:top>
					<v-row justify="end">
						<v-col cols="12" lg="6">
							<v-text-field
								v-model="search"
								label="Search"
								prepend-icon="mdi-magnify"
								class="mx-4"
							></v-text-field>
						</v-col>
					</v-row>
				</template>
				<template v-slot:item.actions="{ item }">
					<v-btn icon @click="editPage(item.id)">
						<v-icon>{{$icons.edit}}</v-icon>
					</v-btn>
				</template>
			</v-data-table>
		</ti-card>
	</v-container>
</template>

<script>
import {sync} from "vuex-pathify";

export default {
	name: "Index",
	computed:{
		tracks: sync('tracks/tracks')
	},
	data: () => ({
	    headers:[
			{
				text: 'Name',
				sortable: true,
				value: 'name',
			},

		]
	}),
}
</script>